document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.checklist input[type="checkbox"]').forEach(checkbox => {
    checkbox.addEventListener('change', (event) => {
      const checkbox = event.target;
      const url = checkbox.dataset.url;
      const params = JSON.parse(checkbox.dataset.params);

      params.state = checkbox.checked ? 'complete' : 'incomplete';

      fetch(url, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(params)
      }).catch(error => {
        console.error('Error updating checklist item:', error);
        checkbox.checked = !checkbox.checked; // Revert on error
      });
    });
  });
});
